import {mapActions, mapMutations} from "vuex";

//sections
import promoSection from '@/modules/home/section/promo/index.vue';
import productionSection from '@/modules/home/section/production/index.vue';
import recipeSection from '@/modules/home/section/recipe/index.vue'
import historySection from '@/modules/home/section/history/index.vue'
import preparationSection from "@/modules/home/section/preparation/index.vue";
import buySection from '@/modules/home/section/buy/index.vue'

export default {
  name: "home",
  components: {
    promoSection,
    productionSection,
    recipeSection,
    historySection,
    preparationSection,
    buySection
  },
  data() {
    return {
      inMove: false,
      inMoveDelay: 400,
      activeSection: 0,
      offsets: [],
      touchStartY: 0
    }
  },
  created() {
    this.getHomePage()
    this.contacts()
  },
  mounted() {},

  computed: {},

  methods: {
    ...mapMutations({}),
    ...mapActions({
      getHomePage: 'home/GET_HOME_PAGE',
      contacts: 'setting/GET_CONTACTS'
    }),
  }
}
