import {mapGetters, mapMutations} from "vuex";

export default {
    name: "promo",
    components: {},
    data() {
        return {
            width: window.innerWidth
        }
    },
    created() {
    },
    mounted() {

    },
    computed: {
        ...mapGetters([
            'languages',
            'currentLanguage',

        ]),
        ...mapGetters({
            content: 'home/homePage'
        })
    },
    methods: {
        ...mapMutations({}),
        smoothScroll(el) {
            const myEl = document.getElementById(el)
            this.$smoothScroll({
                scrollTo: myEl,
            })
        },
        changeLanguage(code) {
            this.$setLanguage(code).then(data => {
                window.location.reload();
            });
        },
        hideActiveLink() {
            let activeItem = document.querySelector('.active')
            activeItem.classList.remove('active__link')
        },
        addActiveLink() {
            let activeItem = document.querySelector('.active')
            activeItem.classList.add('active__link')
        },
    }
}
