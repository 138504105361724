import {mapGetters, mapMutations} from "vuex";
import {Carousel, Slide} from 'vue-carousel';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
    name: "recipe",
    components: {
        Carousel,
        Slide,
        VueSlickCarousel
    },
    watch: {},
    data() {
        return {
            mobileRecipeSlider: 0,
            recipeSlider: {
                "draggable": false,
                "infinite": true,
                "slidesToShow": 3,
                "slidesToScroll": 1,
                "initialSlide": 0,
                arrows: false,
                "responsive": [
                    {
                        "breakpoint": 900,
                        "settings": {
                            "slidesToShow": 2,
                            "slidesToScroll": 1,
                        }
                    },
                    {
                        "breakpoint": 550,
                        "settings": {
                            "slidesToShow": 1,
                            "slidesToScroll": 1,
                            "centerMode": true,
                            "centerPadding": "60px",
                            "focusOnSelect": true,
                        }
                    },
                ]
            },
        }
    },
    created() {
    },
    mounted() {
        this.forceRerender()
    },
    computed: {
        ...mapGetters({
            content: 'home/homePage'
        })
    },
    methods: {
        forceRerender() {
            this.mobileRecipeSlider += 1
        },
        carouselNav($event, direction) {
            const carousel = this.$refs['recipeSlider'];
            carousel.advancePage(direction);
        },
        showNext() {
            this.$refs.recipeSlider.next()
        },
        showPrev() {
            this.$refs.recipeSlider.prev()
        },
    }
}
