import {mapGetters, mapMutations} from "vuex";
import {debounce} from "lodash";
import {Carousel, Slide} from 'vue-carousel';
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'


export default {
  name: "history",
  components: {
    Carousel,
    Slide,
    VueSlickCarousel,
  },
  data() {
    return {
      sliderPageIndex: 0,
      innerWidth: window.innerWidth,
      test: '',
      image: '',
      selectedYear: '',
      description: '',
      years: [],
      recipeSlider: {
        "infinite": true,
        "slidesToShow": 3,
        "slidesToScroll": 1,
        "initialSlide": 0,
        arrows: false,
      },
      historySlider: {
        swipe: false,
        "touchMove": false,
        "infinite": true,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "initialSlide": 0,
        arrows: false,

      },
      historySliderYears: {
        "slidesToShow": 5,
        "slidesToScroll": 1,
        "centerMode": true,
      }

    }
  },
  created() {
  },
  mounted() {


    setTimeout(() => {
      this.content.historyPages.data.forEach(el => {
        this.years.push(el.year)
      })
      this.years.sort()
      this.image = this.content.historyPages.data[0].image
      this.description = this.content.historyPages.data[0].description
    }, 2000)

  },
  computed: {
    ...mapGetters({
      content: 'home/homePage'
    }),
    currentPage() {
      if (this.sliderPageIndex === 0) {
        return 1;
      } else {
        return this.sliderPageIndex / this.carouselSettings.slidesToScroll + 1;
      }
    },
  },
  methods: {
    ...mapMutations({
      showMoreData: 'setting/SHOW_MORE_DATA',
      showMore: 'setting/SHOW_MORE_POPUP',
    }),
    afterPageChange(page) {
      if (window.innerWidth <= 500) {
        this.sliderPageIndex = page;
        this.getContent(this.years[page])
      }

    },
    triggerPopup(item) {
      this.showMore(true)
      this.showMoreData(item)
    },
    getContent: debounce(function (item) {
      const elements = document.querySelectorAll('.yearsItem');
      elements.forEach(el => {
        if (el.innerText === item) {
          el.classList.add('active')
        } else {
          el.classList.remove('active')
        }
      })
      if (this.selectedYear === item) {
        return;
      }
      this.selectedYear = item
      const content = this.content.historyPages.data.find(el => el.year === item)
      this.image = content.image

      this.$refs.historyImage.classList.add('fadeDown')
      this.$refs.historyDescription.classList.add('fadeUp')
      setTimeout(() => {
        this.$refs.historyImage.classList.remove('fadeDown')
        this.$refs.historyDescription.classList.remove('fadeUp')
      }, 1000)
      this.description = content.description
    }, 200),
    carouselNav($event, direction) {
      const carousel = this.$refs['historyPickerSlider'];
      carousel.advancePage(direction);
    },
  }
}
