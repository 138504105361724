import {mapGetters, mapMutations} from "vuex";
import VueSlickCarousel from 'vue-slick-carousel'
import {KinesisContainer, KinesisElement} from "vue-kinesis"
import 'vue-slick-carousel/dist/vue-slick-carousel.css'


export default {
    name: "production",
    components: {
        VueSlickCarousel,
        KinesisContainer,
        KinesisElement,
    },
    data() {
        return {
            productionSlider: {
                "slidesToShow": 1,
                "slidesToScroll": 1,
                "centerMode": true,
                "centerPadding": "120px",
                "focusOnSelect": true,
                "arrows": false,
                "responsive": [
                    {
                        "breakpoint": 520,
                        "settings": {
                            "centerPadding": "100px",
                        }
                    },
                    {
                        "breakpoint": 480,
                        "settings": {
                            "centerPadding": "80px",
                        }
                    },
                    {
                        "breakpoint": 440,
                        "settings": {
                            "centerPadding": "65px",
                        }
                    },
                    {
                        "breakpoint": 400,
                        "settings": {
                            "centerPadding": "50px",
                        }
                    },
                    {
                        "breakpoint": 375,
                        "settings": {
                            "centerPadding": "45px",
                        }
                    },
                ]
            }
        }
    },
    created() {

    },
    computed: {
        ...mapGetters({
            content: 'home/homePage'
        })
    },
    methods: {
        ...mapMutations({
            showMoreData: 'setting/SHOW_MORE_DATA',
            showMore: 'setting/SHOW_MORE_POPUP',
        }),
        triggerPopup(item) {
            this.showMore(true)
            this.showMoreData(item)
        },
        carouselNav($event, direction) {
            const carousel = this.$refs['productsSlider'];
            carousel.advancePage(direction);
        },
    }
}
