import {mapGetters, mapMutations} from "vuex";
import {Carousel, Slide} from 'vue-carousel';
import {KinesisContainer, KinesisElement} from "vue-kinesis"
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
    name: "preparation",
    components: {
        Carousel,
        Slide,
        KinesisContainer,
        KinesisElement,
        VueSlickCarousel
    },
    data() {
        return {
            preparationSlider: {
                "infinite": true,
                "slidesToShow": 3,
                "slidesToScroll": 1,
                "initialSlide": 0,

                arrows: false,
                "responsive": [
                    {
                        "breakpoint": 1050,
                        "settings": {
                            "slidesToShow": 2,
                            "slidesToScroll": 1,
                        }
                    },
                    {
                        "breakpoint": 700,
                        "settings": {
                            "slidesToShow": 1,
                            "slidesToScroll": 1,
                            "centerMode": true,
                            "centerPadding": "180px",
                            "focusOnSelect": true,
                        }
                    },
                    {
                        "breakpoint": 600,
                        "settings": {
                            "slidesToShow": 1,
                            "slidesToScroll": 1,
                            "centerMode": true,
                            "centerPadding": "150px",
                            "focusOnSelect": true,
                        }
                    },
                    {
                        "breakpoint": 500,
                        "settings": {
                            "slidesToShow": 1,
                            "slidesToScroll": 1,
                            "centerMode": true,
                            "centerPadding": "100px",
                            "focusOnSelect": true,
                        }
                    },
                    {
                        "breakpoint": 400,
                        "settings": {
                            "slidesToShow": 1,
                            "slidesToScroll": 1,
                            "centerMode": true,
                            "centerPadding": "80px",
                            "focusOnSelect": true,
                        }
                    }
                ]
            },
        }
    },
    created() {
    },
    computed: {
        ...mapGetters({
            content: 'home/homePage'
        })
    },
    methods: {
        ...mapMutations({
            showMore: 'setting/SHOW_MORE_POPUP',
            showMoreData: 'setting/SHOW_MORE_DATA'
        }),
        triggerPopup(item) {
            this.showMore(true)
            this.showMoreData(item)
        },
        showNext() {
            this.$refs.preparationSlider.next()
        },
        showPrev() {
            this.$refs.preparationSlider.prev()
        },
        carouselNav($event, direction) {
            const carousel = this.$refs['preparationSlider'];
            carousel.advancePage(direction);
        },
    }
}
