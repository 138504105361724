import {mapGetters, mapMutations} from "vuex";
import {Carousel, Slide} from 'vue-carousel';

export default {
  name: "buy",
  components: {},
  data() {
    return {}
  },
  created() {
  },
  computed: {
    ...mapGetters({
      content: 'home/homePage'
    })
  },
  methods: {
    ...mapMutations({}),
    carouselNav($event, direction) {
      const carousel = this.$refs['buyCarousel'];
      carousel.advancePage(direction);
    },
  }
}
